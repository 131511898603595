import React, {useState} from "react";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronDown, faFileDownload} from "@fortawesome/free-solid-svg-icons";
import iconDownloadWhite from "../../images/icons/icon-download-white.svg";

import "./style.scss";

import Layout from "../../components/layout/layout";
import SEO from "../../components/seo";
import HeaderImage from "../../components/header/headerImage/headerImage";
import imgHeader from "../../images/espace-presse/header.webp";
import CommuniquesDePresse from "../../components/communiquesDePresse/communiquesDePresse";
import Retombees from "../../components/retombees/retombees";


const EspacePresse = ({ location, data }) => {
  const communiques = data.allStrapiEspacePresse.nodes[0].Communiques_De_Presse
  const retombees = data.allStrapiEspacePresse.nodes[0].Retombees
  const kitPresses = data.allStrapiEspacePresse.nodes[0].Kit_Presse

  const pageSize = 10

  // sort & filter communiques
  // get all years from communiques
  const dates = communiques.map(c => c.Date_De_Publication);
  let years = dates.map(date => date.split("-")[0]);
  // remove duplicate
  years = [...new Set(years)]
  // more recent first
  years.sort((a,b) => b - a)
  // seulement les 3 dernières années
  years = years.slice(0, 3)

  // state
  const [currentYear, setCurrentYear] = useState(years[0])
  const [currentPageSize, setCurrentPageSize] = useState(pageSize)

  // filter communiques
  const communiquesFiltered = communiques.filter(c =>
    c.Date_De_Publication.split("-")[0] === currentYear
  )

  // sort & filter retombées
  retombees.sort((a,b) => {
      if (a.Date_De_Publication < b.Date_De_Publication) return 1
      if (a.Date_De_Publication > b.Date_De_Publication) return -1
      return 0;
  })
  const retombeesFiltered = retombees.slice(0, currentPageSize)

  return (
    <>
      <Helmet>
        <body className="espace-presse"/>
      </Helmet>
      <Layout>
        <SEO title="Espace Presse" description="Communiqués, kit média, retombées presses récentes… Un espace entièrement dédié aux médias et journalistes qui souhaitent obtenir des informations sur notre entreprise."/>
        <HeaderImage
          title="Espace Presse"
          text="L’Auxiliaire propose une rubrique dédiée aux journalistes regroupant l’ensemble de notre actualité ainsi que des informations factuelles sur notre groupe."
          secondText="Vous pourrez également y trouver un kit presse avec différentes ressources utiles."
          imagePath={imgHeader}
          location={location}
          crumbLabel="Espace Presse"
        />
        <section className="espace-presse__kit-container">
          <div>
            <h2><strong>Kit presse téléchargeable</strong></h2>
            {kitPresses.map((kitPresse, i) => {
              return (
                <a
                  key={i}
                  href={kitPresse.Document.localFile.publicURL}
                  className="btn-file--1"
                  download
                >
                  <img src={iconDownloadWhite} alt="icone download" />
                  <span>{kitPresse.Titre}</span>
                </a>
              )
            })}
          </div>
          <div>
            <h2><strong>Contact presse</strong></h2>
            <a
              href="mailto:communication@auxiliaire.fr"
              className="btn-file--1 btn-2"
              download
            >
              <span>Nous écrire</span>
            </a>
            <a className="btn-file--1 btn-2 div-appel" href="tel:+33472745252"/>
          </div>
        </section>

        <section className="espace-presse__content">
          <h2>
            Nos communiqués <br/>
            <strong>de presse</strong>
          </h2>
          <div className="espace-presse__communiques-filters">
          {
            years
              .map((year, i) => {
              return (
                <div
                  key={i}
                  className={currentYear === year && "selected"}
                  onClick={() => setCurrentYear(year)}
                >
                  {year}
                </div>
              )
            })
          }
          </div>
          <CommuniquesDePresse communiques={communiquesFiltered} />
        </section>

        <section className="espace-presse__content">
          <h2>
            Ils parlent <br/>
            <strong>de nous</strong>
          </h2>
          <Retombees retombees={retombeesFiltered} />
          <div
            className={`
              espace-presse__btn-voir-plus
              ${currentPageSize >= retombees.length && "espace-presse__btn-disabled"}
            `}
            onClick={() => setCurrentPageSize(currentPageSize + pageSize)}
          >
            <p>Voir Plus</p>
            <FontAwesomeIcon icon={faChevronDown} />
          </div>
        </section>
      </Layout>
    </>
  );
};

export const query = graphql`
  query EspacePressePageQuery {
    allStrapiEspacePresse {
      nodes {
        Communiques_De_Presse {
          Date_De_Publication
          Titre
          Document {
            localFile {
              publicURL
            }
          }
        }
        Kit_Presse {
          Titre
          Document {
            localFile {
              publicURL
            }
          }
        }
        Retombees {
          Auteur
          Date_De_Publication
          Titre
          Url
          Document {
            localFile {
              publicURL
            }
          }
        }
      }
    }
  }
`;

export default EspacePresse;
