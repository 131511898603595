import React from "react";

import "./retombees.scss";
import iconDownloadColored from "../../images/icons/icon-download-colored.svg";

export default function Retombees({ retombees }) {
  const months = ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"];

  return (
    <>
      <div className="retombees">
        {
          retombees.map((retombee, i) => {

            // format date
            const dates = retombee.Date_De_Publication.split("-")
            if(dates[1][0] === "0") dates[1] = dates[1].substring(1)
            const date = `${dates[2]} ${months[dates[1]-1]} ${dates[0]}`

            // get url
            let url
            if(retombee.Document !== null)
              url = retombee.Document.localFile.publicURL
            else
              url = retombee.Url

            return (
              <li key={i}>
                <strong>{retombee.Auteur}</strong>
                <span>a parlé de nous le </span>
                <time>{date}</time>
                <br/>
                <span>dans son article</span>
                <a href={url} target={"_blank"}>
                  <span className="retombees__title">{retombee.Titre}</span>
                  <img src={iconDownloadColored} alt="icone download" />
                </a>
              </li>
            )
          })
        }
      </div>
    </>
  )
}
