import React from "react";

import "./communiquesDePresse.scss";
import iconDownloadColored from "../../images/icons/icon-download-colored.svg";

export default function CommuniquesDePresse({ communiques }) {
  return (
    <>
      <div className="communiquesDePresse">
        {
          communiques.map((communique, i) => {
            // format date
            const dates = communique.Date_De_Publication.split("-")
            const date = `${dates[2]}.${dates[1]}.${dates[0]}`
            return (
              <a key={i} href={communique.Document.localFile.publicURL} target={"_blank"}>
                <img src={iconDownloadColored} alt="icone download" />
                <strong className="communiquesDePresse__date">{date}</strong>
                <span className="communiquesDePresse__title">{communique.Titre}</span>
              </a>
            )
          })
        }
      </div>
    </>
  )
}
